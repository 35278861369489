import BlogItem from "components/BlogItem"
import React, { useState } from "react"
import { IBlogPost } from "utils/types"

interface TagPostsProps {
  tagName: string
  posts: IBlogPost[]
}
export default function TagPosts({ tagName, posts }: TagPostsProps) {
  const [batch, setBatch] = useState(1)

  const postsPerBatch = 18
  const numberOfBatches = Math.ceil(posts.length / postsPerBatch)

  const postsShownCount = postsPerBatch * batch

  return (
    <div className="flex mb-32 px-[7%] justify-center">
      <div className="max-w-7xl w-full">
        <div className="mt-0">
          <h2 className="text-xl capitalize md:text-3xl font-medium mb-5 md:mb-10">
            Tags: {tagName}
          </h2>
          <div className="grid  md:grid-cols-2 xl:grid-cols-3 gap-x-12 gap-y-16">
            {posts.slice(0, postsShownCount).map(post => (
              <BlogItem key={post.id} post={post} />
            ))}
          </div>

          <hr className="mt-10" />

          <div className="mt-7 flex justify-center">
            {batch !== numberOfBatches && (
              <button
                onClick={() => setBatch(batch + 1)}
                className="border py-3 text-sm px-8 rounded-md bg-slate-50 hover:bg-slate-100 font-medium text-gray-700"
              >
                LOAD MORE
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
