import Layout from "components/Layout"
import SEO from "components/SEO"
import { graphql, PageProps } from "gatsby"
import TagPosts from "modules/TagPosts/TagPosts"
import React from "react"
import { IBlogPost } from "utils/types"

interface TagPostsTemplateProps extends PageProps {
  pageContext: {
    tagName: string
  }
  data: {
    allContentfulBlogPost: {
      nodes: IBlogPost[]
    }
  }
}

export default function TagPostsTemplate({
  data,
  pageContext,
}: TagPostsTemplateProps) {
  return (
    <Layout>
      <SEO title={`Tags: ${pageContext.tagName}`} />
      <TagPosts
        tagName={pageContext.tagName}
        posts={data.allContentfulBlogPost.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostsByTag($tagName: [String]) {
    allContentfulBlogPost(filter: { tags: { in: $tagName } }) {
      nodes {
        id
        slug
        subtitle
        title
        description
        authorName
        captionText
        tags
        date(formatString: "DD MMMM YYYY")
        createdAt(formatString: "DD MMMM YYYY")
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        authorImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        body {
          raw
        }
      }
    }
  }
`
